import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Collapse } from 'antd';
import { getFinalPrice , Utils } from 'src/shared/lib';
import { CopyOutlined, EditOutlined, PictureOutlined } from '@ant-design/icons';
import { PostInfo } from 'src/entities/postInfo';
import { OrdersTask } from 'src/pages/orders/order/types';
import { submitCancelIntermedary, submitChangeStatus } from 'src/pages/orders/order/model';
import { availableCancelIntermediary, availableChangeStatus } from 'src/utils';

import { CancelIntermedaryModal } from './components/CancelIntermedaryModal';
import styles from './OrdersProductsInfo.module.less';
import { ChangeStatusModal } from './components/ChangeStatusModal';
import { TOrderProductInfo } from './OrdersProductsInfo.types';

export interface OrdersProductsInfoProps {
  data: TOrderProductInfo;
  task: OrdersTask;
  index: number;
}

export const OrdersProductsInfo = ({ data, task, index }: OrdersProductsInfoProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showIntermediaryModal, setShowIntermediaryModal] = useState<boolean>(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false);

  const handleDescription = (data: string | string[]) => {
    const isCollapseExpanded = Array.isArray(data) ? !!data.length : !!data;
    setIsExpanded(isCollapseExpanded);
  };

  return (
    <article className={styles.body}>
      <div className={styles.header}>
        <h3 className={styles.title}>
          {data?.id ? <Link to={`/task/${data.id}`} state={{ prevPath: true }}>{`№${data.code || '-'}`}</Link> : '-'}
          {data.code && (
            <CopyOutlined style={{ fontSize: 14 }} onClick={() => navigator.clipboard.writeText(data.code)} />
          )}
        </h3>
        <div className={styles.status}>
          <p>{data?.status_name || '-'}</p>
          <p className={styles.quantity}>
            {data?.received_quantity ?? '-'}/{data?.quantity ?? '-'}
          </p>
          {availableChangeStatus.includes(data.status) && (
            <EditOutlined onClick={() => setShowChangeStatusModal(true)} />
          )}
        </div>
      </div>
      <div className={styles.mainInfoWrapper}>
        <div className={styles.wrapper}>
          <Avatar
            className={styles.avatar}
            shape="square"
            size={100}
            icon={<PictureOutlined />}
            src={data?.image || ''}
          />
          <div className={styles.info}>
            <p className={styles.name}>{data?.name || '-'}</p>
            <p>
              <strong>Количество: </strong> <span>{data?.quantity ? `${data.quantity} шт` : '-'}</span>
            </p>
            <p>
              <strong>Cумма: </strong> <span>{data?.amount ? getFinalPrice(data.amount) : '-'}</span>
            </p>
          </div>
          <div className={`${styles.info} ${styles.source}`}>
            <p>
              <strong>Источник: </strong>
              <span>
                {task?.post?.source?.url ? (
                  <a href={task.post.source.url} target="_blank" rel="noreferrer">
                    {task.post.source?.name || task.post.source.url}
                  </a>
                ) : (
                  '-'
                )}
              </span>
              <span>{task?.pavilion?.name || '-'}</span>
            </p>
            <p className={styles.intermediary}>
              <strong>Посредник: </strong>
              {task?.intermediary?.id ? (
                <>
                  {availableCancelIntermediary.includes(data.status) && (
                    <EditOutlined onClick={() => setShowIntermediaryModal(true)} />
                  )}
                  <Link to={`/profile/intermediary/${task.intermediary.id}`} state={{ prevPath: true }}>
                    {task.intermediary?.name || '-'}
                  </Link>
                </>
              ) : (
                <span>-</span>
              )}
              <span>
                {task?.task?.take_in_work_date
                  ? Utils.getFormatDate(task.task.take_in_work_date, 'DD.MM.YYYY HH:mm')
                  : ''}
              </span>
            </p>
          </div>
        </div>
      </div>
      {task?.post ? (
        <Collapse accordion onChange={handleDescription} ghost expandIconPosition="end">
          <Collapse.Panel
            header={isExpanded ? 'Свернуть описание' : 'Развернуть описание'}
            key={task.post?.id || index}>
            <PostInfo post={task.post} />
          </Collapse.Panel>
        </Collapse>
      ) : null}
      {showIntermediaryModal && (
        <CancelIntermedaryModal
          isOpen={showIntermediaryModal}
          setIsOpen={setShowIntermediaryModal}
          confirmHandler={() => submitCancelIntermedary(data.id)}
          item={{
            data,
            task,
          }}
        />
      )}
      {showChangeStatusModal && (
        <ChangeStatusModal
          isOpen={showChangeStatusModal}
          setIsOpen={setShowChangeStatusModal}
          confirmHandler={() => submitChangeStatus(data.id)}
          item={{
            data,
            task,
          }}
        />
      )}
    </article>
  );
};
