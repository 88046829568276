import { getFinalPrice } from 'src/shared/lib';
import { TOrderPrices } from 'src/shared/api/queries/ordersApi';
import {useStore} from "effector-react";

import { OrderRefund } from '../orderRefund';
import styles from './OrdersPrice.module.less';
import {$order} from "../../model";


interface OrdersPriceProps {
  prices: TOrderPrices;
  id: string;
}
export const OrdersPrice = ({ prices, id }: OrdersPriceProps) => {
  const order = useStore($order);
  const refundSum = order?.order?.check.filter((el) => el.refunded !== 0).reduce((prev, cur) =>
      prev + (cur.price * cur.refunded), 0);
  return (
      <article className={styles.ordersPrice}>
        <div className={styles.prices}>
          <div className={styles['title-prices']}>
            <h3 className={styles.title}>Стоимость</h3>
            <OrderRefund id={id}/>
          </div>
          <p>
            <span>Сумма заказа</span>
            <span>
          {prices.at_the_beginning.products === prices.now.products ? (
              getFinalPrice(prices.at_the_beginning.products)
          ) : (
              <>
                <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.products)}</span>
                {getFinalPrice(prices.now.products)}
              </>
          )}
        </span>
          </p>
          <p>
        <span>
          Комиссия
          {prices.at_the_beginning.margin_percent === prices.now.margin_percent ? (
              ` (${prices.at_the_beginning.margin_percent}%)`
          ) : (
              <>
                <span className={styles['beginning-price']}>({prices.at_the_beginning.margin_percent}%)</span> (
                {prices.now.margin_percent}
                %)
              </>
          )}
        </span>
            <span>
          {prices.at_the_beginning.margin === prices.now.margin ? (
              getFinalPrice(prices.at_the_beginning.margin)
          ) : (
              <>
                <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.margin)}</span>
                {getFinalPrice(prices.now.margin)}
              </>
          )}
        </span>
          </p>
          <p>
            <span>Доставка</span>
            <span>
          {prices.at_the_beginning.delivery === prices.now.delivery ? (
              getFinalPrice(prices.at_the_beginning.delivery)
          ) : (
              <>
                <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.delivery)}</span>
                {getFinalPrice(prices.now.delivery)}
              </>
          )}
        </span>
          </p>
          <p>
            <strong>Итого оплачено:</strong>
            <span>
          {prices.at_the_beginning.total === prices.now.total ? (
              getFinalPrice(prices.at_the_beginning.total)
          ) : (
              <>
                <span className={styles['beginning-price']}>{getFinalPrice(prices.at_the_beginning.total)}</span>
                {getFinalPrice(prices.now.total)}
              </>
          )}
        </span>
          </p>
         {/* { order?.order?.check.filter((el) => el.refunded !== 0).map((el) =>
                  <p>
                    <span>{`Возврат ${el.refunded} шт "${el.name}"`}</span>
                    <span>
                      <span>{getFinalPrice(el.refunded * el.price)}</span>
                    </span>
                  </p>
              )
          } */}
            {!!refundSum &&
                <p>
                    <span>Возврат</span>
                    <span>
                          <span>-{getFinalPrice(refundSum)}</span>
                        </span>
                </p>
            }
            {!!refundSum && <p>
                <strong>Итого с учётом возврата:</strong>
                <span>
                  {getFinalPrice(prices.now.total - refundSum)}
                </span>
            </p>
            }
        </div>
      </article>
  );
}
