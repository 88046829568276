import {Button, Empty, Table} from "antd";
import React, {useEffect, useState} from "react";
import {MainModal} from "src/shared/ui";
import {useStore} from "effector-react";
import {TableRowSelection} from "antd/lib/table/interface";
import {getFinalPrice} from "src/shared/lib";

import {$order, submitRefundReqData} from "../../model";
import {columns} from './config';
import styles from './OrderRefound.module.less';
import { IOrderCheck } from '../../types';

const OrderRefund = ({id} : {id: string}) => {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);
    const [refundInfo, setRefundInfo] = useState<number[]>([]);
    const order = useStore($order);
    useEffect(() => {
        if (order && showModal) {
            setRefundInfo(order?.order?.check.map(({quantity}) => quantity))
        }
    }, [order, showModal])
    const handleSelectChange = (selectedRowKeys: React.Key[], selectedRows: IOrderCheck[]) => {
        const ids = selectedRows.map(({ name }) => name);
        setSelected(ids);
    };

    const rowSelection: TableRowSelection<IOrderCheck> = {
        checkStrictly: true,
        getCheckboxProps: (record) => ({
            disabled: record.initRefunded === record.quantity,
        }),
        onChange: handleSelectChange,
    };
    const sendArr = selected.map((sel) => {
        const index = order?.order?.check.findIndex(({name}) => name === sel);
        if (index !== undefined && order?.order?.check[index]) {
            const { refunded, ...other } = order.order.check[index];
            return {
                ...other,
                quantity: refundInfo[index]
            }
        }
        return null;
    }).filter(numb => numb !== null);
    const refundArr = selected.map((sel) => {
        const index = order?.order?.check.findIndex(({name}) => name === sel);
        if (index !== undefined && typeof order?.order?.check?.[index]?.price === 'number') {
            return  order.order.check[index].price * refundInfo[index]
        }
        return null;
    }).filter(numb => numb !== null);
    // @ts-ignore
    const refundSum = +refundArr.reduce((prev, cur) => prev + cur, 0)
    return (
        <div className={styles.refundList}>
            <Button className={styles.opener} onClick={() => setShowModal(true)}>Возврат</Button>
            <MainModal
                isOpen={showModal}
                closeHandler={() => setShowModal(false)}
                title="Возврат платежа"
                width={1024}
            >
                <Table
                    rowSelection={rowSelection}
                    rowKey={record => record.name}
                    bordered
                    columns={columns}
                    dataSource={order?.order?.check.map(({refunded, ...other}, index) => ({
                        ...other,
                        refunded: refundInfo[index],
                        initRefunded: refunded,
                        onRefundChange: (value: number) => setRefundInfo((cur) => {
                            const newArr = [...cur];
                            newArr[index] = value;
                            return newArr;
                        })
                    }))}
                    locale={{ emptyText: <Empty description="Нет данных" /> }}
                    pagination={false}
                    className={styles.refundList}
                />
                <span className={styles.refundTotal}>{`Сумма к возврату: ${getFinalPrice(refundSum)}`}</span>
                <span className={styles.refundSelected}>{`Выбрано ${selected.length} из ${order?.order?.check?.length}`}</span>
                <div className={styles.buttons}>
                    <Button type="primary" disabled={selected.length === 0} className={styles.opener} onClick={() => {
                        // @ts-ignore
                        submitRefundReqData({
                            admin_id: 0,
                            items: sendArr,
                            id,
                        })
                    }}>Подтвердить возврат</Button>
                    <Button className={styles.opener} onClick={() => setShowModal(false)}>Отмена</Button>
                </div>
            </MainModal>
        </div>
    )
}

export { OrderRefund };