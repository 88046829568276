import type { ColumnsType } from 'antd/es/table';
import {getFinalPrice} from "src/shared/lib";
import {Avatar, InputNumber} from "antd";
import {PictureOutlined} from "@ant-design/icons";

import { IOrderCheck } from '../../types';
import styles from './OrderRefound.module.less'

export const PER_PAGE = 10;

export const initState = {
  pagination: {
    per_page: PER_PAGE,
    page: 1,
  },
};

export const mainColumnName = 'name';

export const columns: ColumnsType<IOrderCheck> = [
  {
    title: 'Изображение',
    key: 'order_id',
    render: data => <Avatar
        shape="square"
        size="large"
        icon={<PictureOutlined />}
        src={data?.image}
        className={styles.avatar}
    />,
    width: '132px',
  },
  {
    title: 'Наименование',
    key: 'name',
    render: data => data.name,
  },
  {
    title: 'Цена, ₽',
    dataIndex: 'price',
    key: 'price',
    render: data => {
      const resultPrice = getFinalPrice(data);
      return data ? resultPrice : '-';
    },
    width: '120px',
  },
  {
    title: 'Оплачено, шт',
    key: 'quantity',
    render: data => data.quantity,
    width: '105px',
  },
  {
    title: 'К возврату, шт',
    key: 'quantity',
    render: data => <InputNumber disabled={data.initRefunded === data.quantity} max={data.quantity} value={data.initRefunded === data.quantity ? 0 : data.refunded} onChange={(value) => data?.onRefundChange(value)}/>,
    width: '135px',
  },
  {
    title: 'К возврату, ₽',
    key: 'quantity',
    render: data => {
      const resultPrice = getFinalPrice(data.initRefunded === data.quantity ? 0 : data.price * data.refunded);
      return data ? resultPrice : '-';
    },
    width: '130px',
  },
];

export const initColumnSettings = columns.reduce((prev, curr) => {
  if (curr.key) {
    prev[curr.key] = true;
  }
  return prev;
}, {} as Record<string, boolean>);